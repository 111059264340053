import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import ScaledImg from './ScaledImg';
import './TimeLine.css'
import StarIcon from '@material-ui/icons/Star';
import Subject from '@material-ui/icons/Subject'
import Settings from '@material-ui/icons/Settings'
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import HighlightIcon from '@material-ui/icons/Highlight';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SaveIcon from '@material-ui/icons/Save';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AppsIcon from '@material-ui/icons/Apps';
import LineStyleIcon from '@material-ui/icons/LineStyle';
import FilterFramesIcon from '@material-ui/icons/FilterFrames';
import LinearScaleIcon from '@material-ui/icons/LinearScale';

const categoryBoth = '开源版 专业版'
const categoryPro = '专业版'
const txt = [
    {
        title: '时间线',
        icon: <LinearScaleIcon />,
        desc: `时间线类似书签功能，按时间顺序排列节点，但比书签功能更丰富地。通过在主窗口里右键标记，可以将那一行新增为时间线的一个节点。
        刚开始分析日志时，时间线是书签和线索，可以用不同的颜色分类不同模块或是不同线索主线，并把一些思考记录到备注里；当日志分析出眉目后，要整理出问题原因或者发生的时序，这时可以删除时间线里的无关节点，最终得出分析报告`,
        readmore: '/manual/analyze_timeline.html',
        category: categoryBoth
    },
    {
        title: '过滤窗',
        icon: <FilterFramesIcon />,
        img: 'https://s1.ax1x.com/2022/10/12/xUoVQf.png',
        desc: `过滤窗口可以帮助我们搜集仅含关键词的行，以集中观察某一范畴的日志。比如我们可以根据日志规范，过滤某个TAG，或者根据PID过滤某个进程等等。
        现在您可以同时打开多个过滤窗口，或是对过滤窗口的内容再进行过滤。
        双击过滤窗口中的一行，可以同步定位主窗口和时间线，反之亦然`,
        readmore: '/manual/analyze_filter.html',
        category: categoryBoth
    },
    {
        title: '打开大文件',
        icon: <LineStyleIcon />,
        desc: `支持几百M乃至几G的文件秒开，不再为了只查一行日志焦急等待十几分钟（，还可能等来电脑死机）
        目前只支持专业版，开源版支持100M以下文件`,
        category: categoryPro
    },
    {
        title: '跨平台',
        icon: <AppsIcon />,
        desc: `得益于成熟的QT框架，Loginsight不仅可以在Windows/Linux/Mac上运行，而且具有本地化的界面风格和操作习惯。`,
        img: 'https://z3.ax1x.com/2021/06/06/2Njhan.md.png',
        category: categoryBoth
    },
    {
        title: '多标签',
        icon: <FileCopyIcon />,
        img: 'https://s1.ax1x.com/2022/10/15/x0rZXd.png',
        desc: `支持同时打开多个日志文件，在一个窗口内通过标签页快速切换，流畅不分心
        借助多文档，我们还可以查看多份日志是否出现相同现象；亦或是结合系统的多个模块同一时间点的日志综合分析问题`,
        readmore: '/manual/analyze_tabs.html',
        category: categoryBoth
    },
    {
        title: '格式语法',
        icon: <Subject />,
        img: 'https://s1.ax1x.com/2022/09/08/vbvNwR.png',
        desc: `通过正则表达式添加自定义的语法高亮，支持各式各样的日志格式，
        只需一瞥，日期、优先级、进程号了然于心，让日志看起来一目了然`,
        category: categoryPro
    },
    {
        title: '打开粘贴板',
        icon: <AssignmentIcon />,
        desc: `loginsight支持直接导入粘贴板中的文本，可以省略”先将粘贴板文本保存到文件，再从loginsight打开的步骤“。在一些情况下会带来便捷`,
        readmore: '/manual/open_clipboard.html',
        category: categoryBoth
    },
    {
        title: '打开分段日志',
        icon: <FileCopyIcon />,
        img: 'https://s1.ax1x.com/2022/09/26/xVlGpn.png',
        desc: `loginsight支持打开分段日志，自动拼接多份日志，让你犹如在浏览单文件日志。不仅如此，还设计了贴心的排序规则，支持各种分段日志的命名规则`,
        category: categoryPro
    },
    {
        title: '打开后台程序',
        icon: <AmpStoriesIcon />,
        desc: `借助loginsight，可以查看后台程序的实时输出。
        通过其他程序的加持，loginsight可以发挥更大的作用。
        比如：通过启动adb，我们可以用loginsight查看Android设备的实时日志；通过启动dmesg，我们可以监听linux系统的内核消息……`,
        img: 'https://s1.ax1x.com/2022/10/15/x0Bwbq.png',
        readmore: '/manual/open_process.html',
        category: categoryPro
    },
    {
        title: '保存工程',
        icon: <AccountTreeIcon />,
        desc: `如果日志分析需要很久时间，可以通过保存工程菜单，将搜索过的单词、高亮的关键字、过滤的内容、时间线等信息保存到工程文件。下次再打开工程的时候，就可以恢复到上次的工作状态`,
        img: 'https://z3.ax1x.com/2021/04/12/cDyfaj.png',
        readmore: '/manual/open_project.html',
        category: categoryBoth        
    },
    {
        title: '导出时间线',
        icon: <SaveIcon />,
        img: `https://z3.ax1x.com/2021/04/12/cDhQ9H.png`,
        desc: `将这个图片作为分析报告（或是其中的附图），介绍日志分析的整个过程，让报告更加具体生动`,
        category: categoryBoth
    },
    {
        title: '导出过滤窗内容',
        icon: <FileCopyIcon />,
        desc: `当过滤后的内容需要被分享，或进一步深入分析，将它导出到文件是一个不错的选择
        您也可以将这个功能当做一个小工具，帮助做文件处理或数据整理`,
        category: categoryPro
    },
    {
        title: '多关键词高亮',
        icon: <HighlightIcon />,
        desc: `Loginsight在工具栏为用户设计了TAG栏，用于管理关键词、设置其高亮色`,
        img: 'https://s1.ax1x.com/2022/10/15/x0rV6H.png',
        readmore: '/manual/analyze_highlight.html',
        category: categoryBoth
    },
    {
        title: '准确跳转',
        icon: <CenterFocusWeakIcon />,
        desc: `按行号跳转、历史位置回溯、关联跳转、跳转到文件末尾……多种跳转方式，帮助在多次导航过程准确跳转，指哪打哪`,
        readmore: '/manual/analyze_goto.html',
        category: categoryBoth
    },
    {
        title: '个性化设置',
        icon: <Settings />,
        desc: `loginsight提供了几个关键的设置项，可按各自喜好调节参数
        loginsight支持像常规文本框一样，切换折行、非折行显示，自由复制任意行，或从行中间开始复制`,
        img: 'https://s1.ax1x.com/2022/10/15/x0DKWF.png',
        readmore: '/manual/settings.html',
        category: categoryBoth
    },
    {
        title: '详细文档',
        icon: <Subject/>,
        img: 'https://z3.ax1x.com/2021/06/06/2UAGGQ.md.png',
        desc: '',
        readmore: '/manual',
        category: categoryBoth
    },
]

const TimeLine = ()=>{
    const ReadMore = (props)=>{
        if (props.link !== undefined) {
            return <a href={props.link}>了解更多</a>
        }
        return ''
    }
    const Preview = (props)=>{
        if (props.src !== undefined) {
            return <ScaledImg src={props.src}></ScaledImg>
        }
        return ''
    }
    const elems = txt.map(item=>{
        const red = { background: 'rgb(233, 30, 99)', color: '#fff' }
        const blue = { background: 'rgb(33, 150, 243)', color: '#fff' }
        const isPro = item.category === categoryPro
        return <VerticalTimelineElement
            className={isPro ? 'vertical-timeline-element--red' : 'vertical-timeline-element--blue'}
            iconStyle={isPro ? red : blue}
            date={item.category}
            icon={item.icon}>
            <h3 className="vertical-timeline-element-title">{item.title}</h3>
            {
                item.desc.split('\n').map(line=><p>{line}</p>)
            }
            <p><Preview src={item.img}></Preview></p>
            <p><ReadMore link={item.readmore}></ReadMore></p>
        </VerticalTimelineElement>
    })
    return (
        <div className='TimeLine'>
            <VerticalTimeline className="vertical-timeline-custom-line">
                {elems}
                <VerticalTimelineElement
                    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                    icon={<StarIcon />}>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </div>
    )
}

export default TimeLine