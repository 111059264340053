import React from 'react';
import { NavLink } from 'react-router-dom';
import URL from 'url-parse';

export default function (props) {
    const { to, children, newTab, className } = props;
    const { origin, pathname, query } = new URL(to);
    const linkProps = {
        ...props,
        to: pathname + query
    };
    return (
        (origin === window.location.origin && !newTab)
        ? <NavLink {...linkProps} />
        : <a className={className} href={to} target="_blank">{children}</a>
    );
}