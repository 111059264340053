import IconButton from './IconLinkButton';
import ReactSwiper from 'reactjs-swiper'
import './FirstLook.css'


function FirstLook() {
  const items = [
    {
      image: 'https://s1.ax1x.com/2022/10/12/xU5e9P.png',
      title: '首页',
      link: 'https://s1.ax1x.com/2022/10/12/xU5e9P.png'
    },
    {
      image: 'https://s1.ax1x.com/2022/10/12/xU5DE9.png',
      title: '主窗口',
      link: 'https://s1.ax1x.com/2022/10/12/xU5DE9.png'
    }
  ]
  const swiperOptions = {
    preloadImages: false,
    autoplay: 4000,
    autoplayDisableOnInteraction: false
  };

  return (
    <div className="firstlook">
      <div className="describe">
        <h1>日志千般复杂，Loginsight一招制敌</h1>
        <p>Loginsight是一款开源的通用日志分析工具，能快速打开大文件，且集成了时间线、过滤窗、正则搜索、分享等功能，有效帮助理清线索、记录思路、整理分析结果</p>
        <div className='hbox buyit'>
          <IconButton icon='/github.png' title='GitHub' to='https://github.com/compilelife/loginsight'></IconButton>
          <IconButton icon='/gitee.png' title='Gitee' to='https://gitee.com/compilelife/loginsight'></IconButton>
        </div>
      </div>
      <ReactSwiper swiperOptions={swiperOptions} items={items} />
    </div>
  );
}

export default FirstLook;
