import React from "react"

class Discussion extends React.Component {
    render() {
        return <div style={{margin: '0 20px'}}>
            <h2>讨论区</h2>
            <p>欢迎各位在此提出功能建议和需求，说说你的想法。我们会不定期来筛选和实现一些好的点子</p>
            <div id="lv-container" data-id="city" data-uid="MTAyMC80ODk4OS8yNTQ4Mw=="></div>
        </div>
    }

    componentDidMount() {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://cdn-city.livere.com/js/embed.dist.js';
        script.async = true
        document.getElementById('lv-container').appendChild(script);
    }
}

export default Discussion