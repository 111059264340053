import './Footer.css'

const Footer = ()=>(
    <div className='Footer'>
        <div className='hbox'>
            <img alt="" src='/12377.png'></img>
            <a href="https://12377.cn">违法和不良信息举报中心</a>
        </div>
        <div className='hbox'>
            <img alt="" src='/gonganbeian.png'></img>
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35012102500334">闽公网安备 35012102500334号</a>
        </div>
        <a href="http://beian.miit.gov.cn/">闽ICP备2021006737号</a>
        <a href='mailto: compilelife@163.com'>联系方式：compilelife@163.com</a>
    </div>
)

export default Footer