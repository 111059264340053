import Title from './Title'
import './App.css'
import Home from './Home';
import {Route, Switch} from "react-router-dom";
import Footer from './Footer';
import Discussion from './Discussion';

function App() {
  return (
    <div className="App">
        <Title/>
        <div className='content'>
          <Switch>
            <Route path='/' exact={true} component={Home}></Route>
            <Route path='/Discussion' component={Discussion}></Route>
            <Route to='/'></Route>
          </Switch>
        </div>
        <Footer />
    </div>
  );
}

//文案

export default App;