import { NavLink } from 'react-router-dom';
import './Title.css';

function App() {
  return (
    <div className='Title'>
      <NavLink className='TitleLeft' to='/'>
        <img src='/logo.png' width='40' height='40' alt="logo" />
        <p>Loginsight</p>
      </NavLink>
      <div className='TitleRight'>
        <a href='https://gitee.com/compilelife/loginsight' target="_blank">Gitee</a>
        <a href='https://github.com/compilelife/loginsight' target="_blank">Github</a>
        <a href='/manual'>文档</a>
        <a href='/Discussion'>讨论区</a>
      </div>
    </div>
  )
}

export default App;
