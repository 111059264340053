import FirstLook from './FirstLook';
import TimeLine from './TimeLine'

const Home = () => {
    return (
        <div>
            <FirstLook />
            <TimeLine />
        </div>
    )
}

export default Home