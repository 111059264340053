import React from "react";
import './IconLinkButton.css'
import Link from "./Link";

const IconLinkButton = (props)=>{
    return (
        <Link to={props.to} className='wrapper'>
            <img src={props.icon} className='scaledImg' alt=''></img>
            <p>{props.title}</p>
        </Link>
    )
}

export default IconLinkButton;